import { Button, Form, Modal } from 'antd';
import { DateTime } from 'luxon';
import React from 'react';
import toast from 'react-hot-toast';
import { IconClose } from '../../utils/Icons';
import { InputUi } from '../ui';

import { setOfficePlanRequest } from '../../models/office/office';

const EditPlanModal = ({
  isModalOpen,
  selectedEditOffice,
  fetchPlan,
  handleCancel,
  officesList,
}) => {
  const [form] = Form.useForm();

  const successForm = () => {
    if (!officesList) return console.error('Offices list is empty!');
    if (!selectedEditOffice)
      return console.error('The selected office ID does not exist!');
    const startOfCurrentWeek = DateTime.now()
      .startOf('week')
      .toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");

    setOfficePlanRequest(
      selectedEditOffice,
      startOfCurrentWeek,
      Number(form.getFieldValue('usdt')),
    )
      .then(() => {
        console.log('Changed');
        fetchPlan(officesList); // refetch
      })
      .catch((err) => {
        console.warn('Error setOfficePlanRequest', err);

        // Check if the error is an Axios error to get a more descriptive message
        const errorMessage = err.response
          ? err.response.data.message || 'Set office plan error'
          : 'Network error';

        // Show error toast
        toast.error(errorMessage);
      });
    handleCancel();
    form.resetFields();
  };

  const errorForm = () => {
    console.error('Error');
  };

  return (
    <Modal
      title="Редактировать план"
      open={isModalOpen}
      onCancel={handleCancel}
      className="modal-closed"
      closeIcon={<IconClose width="21px" height="21px" fill="#d94c48" />}
      footer={null}
    >
      <Form
        form={form}
        name="editPlan"
        layout="vertical"
        onFinish={successForm}
        onFinishFailed={errorForm}
        autoComplete="off"
      >
        <InputUi
          name="usdt"
          label="Введите Сумму (USDT)"
          placeholder={`1000`}
          errorMess={`Пожалуйста, введите сумму в USDT`}
        />

        <div className="flex justify-center gap-10 mt-30">
          <Form.Item shouldUpdate>
            {() => (
              <Button
                htmlType="submit"
                disabled={
                  !form.isFieldsTouched(true) ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length)
                    .length
                }
                className="xs"
              >
                Сохранить
              </Button>
            )}
          </Form.Item>
          <Button onClick={handleCancel} className="xs">
            Закрыть
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default EditPlanModal;
