import React from "react";

const IconLogIn = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d="M9,7V6a2.652,2.652,0,0,1,3-3h6a2.652,2.652,0,0,1,3,3V18a2.652,2.652,0,0,1-3,3H12a2.652,2.652,0,0,1-3-3V17"
        />
        <path
          fill={fill}
          d="M16.692,11.713a.771.771,0,0,0-.162-.244l-3-3a.75.75,0,0,0-1.06,1.06l1.719,1.72H3a.75.75,0,0,0,0,1.5H14.189L12.47,14.47a.75.75,0,1,0,1.06,1.06l3-3a.771.771,0,0,0,.162-.244A.751.751,0,0,0,16.692,11.713Z"
        />
      </g>
    </svg>
  );
};

export default IconLogIn;
