import axios from 'axios';
import Cookies from 'universal-cookie';
import { apiDomain } from '../../config/consts';

export const getUserHistoryRequest = async (
  userId,
  page = 1,
  limit = 10,
  day,
  action,
) => {
  const cookies = new Cookies();
  const atToken = cookies.get('at');

  let composeQueries = `${userId}?page=${page}&limit=${limit}`;
  if (action) composeQueries += `&action=${action}`;
  if (day) composeQueries += `&day=${day}`;

  return axios.get(`${apiDomain}/user-history/${composeQueries}`, {
    headers: {
      Authorization: `Bearer ${atToken}`,
    },
  });
};

export const getAllHistoryRequest = async (page = 1, limit = 10) => {
  const cookies = new Cookies();
  const atToken = cookies.get('at');

  return axios.get(`${apiDomain}/all-history?page=${page}&limit=${limit}`, {
    headers: {
      Authorization: `Bearer ${atToken}`,
    },
  });
};

export const getUserHistoryLengthRequest = async (userId) => {
  const cookies = new Cookies();
  const atToken = cookies.get('at');

  if (!userId)
    return console.error('getUserHistoryLengthRequest: userId is mossed!');

  return axios.get(`${apiDomain}/user-history-length/${userId}`, {
    headers: {
      Authorization: `Bearer ${atToken}`,
    },
  });
};

export const getHistoryLengthRequest = async () => {
  const cookies = new Cookies();
  const atToken = cookies.get('at');

  return axios.get(`${apiDomain}/all-history-length`, {
    headers: {
      Authorization: `Bearer ${atToken}`,
    },
  });
};

export const getAvailableOfficesRequest = async () => {
  const cookies = new Cookies();
  const atToken = cookies.get('at');

  return axios.get(`${apiDomain}/available-offices`, {
    headers: {
      Authorization: `Bearer ${atToken}`,
    },
  });
};

export const createClosedClientRequest = async (
  OfficeDailyDataId,
  Name,
  Number,
  City,
) => {
  const cookies = new Cookies();
  const atToken = cookies.get('at');

  if (OfficeDailyDataId && Name && Number && City) {
    return axios.post(
      `${apiDomain}/create-closed-client`,
      {
        OfficeDailyDataId,
        Name,
        Number,
        City,
      },
      {
        headers: {
          Authorization: `Bearer ${atToken}`,
        },
      },
    );
  } else {
    console.error('Create closed client: some of reuqired data is missed.');
  }
};

export const resetPasswordSuperadminRequest = async (Password) => {
  const cookies = new Cookies();
  const atToken = cookies.get('at');

  if (Password) {
    return axios.post(
      `${apiDomain}/reset-password`,
      {
        Password,
      },
      {
        headers: {
          Authorization: `Bearer ${atToken}`,
        },
      },
    );
  } else {
    console.error('Reset password: some of reuqired data is missed.');
  }
};
