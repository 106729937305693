import React from "react";

const IconClipboardList = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d="M16,5h1a2.652,2.652,0,0,1,3,3V18a2.652,2.652,0,0,1-3,3H7a2.652,2.652,0,0,1-3-3V8A2.652,2.652,0,0,1,7,5H8"
        />
        <path
          fill={fill}
          d="M16,4.5v1A1.326,1.326,0,0,1,14.5,7h-5A1.326,1.326,0,0,1,8,5.5v-1A1.326,1.326,0,0,1,9.5,3h5A1.326,1.326,0,0,1,16,4.5Z"
        />
        <path
          fill={fill}
          d="M16.5,12.75h-4a.75.75,0,0,1,0-1.5h4a.75.75,0,0,1,0,1.5ZM17.25,16a.75.75,0,0,0-.75-.75h-4a.75.75,0,0,0,0,1.5h4A.75.75,0,0,0,17.25,16ZM8.7,13.2l1.333-1.333A.75.75,0,0,0,8.97,10.8l-.8.8-.137-.137a.75.75,0,0,0-1.06,1.062l.667.666a.748.748,0,0,0,1.06,0Zm0,4,1.333-1.333A.75.75,0,0,0,8.97,14.8l-.8.8-.137-.137a.75.75,0,0,0-1.06,1.062l.667.666a.748.748,0,0,0,1.06,0Z"
        />
      </g>
    </svg>
  );
};

export default IconClipboardList;
