import React from "react";

const IconTimesSquare = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d="M3,17.625V6.375Q3,3,6.375,3h11.25Q21,3,21,6.375v11.25Q21,21,17.625,21H6.375Q3,21,3,17.625Z"
        />
        <path
          fill={fill}
          d="M13.061,12,15.53,9.53a.75.75,0,0,0-1.06-1.06L12,10.939,9.53,8.47A.75.75,0,0,0,8.47,9.53L10.939,12,8.47,14.47a.75.75,0,1,0,1.06,1.06L12,13.061l2.47,2.469a.75.75,0,0,0,1.06-1.06Z"
        />
      </g>
    </svg>
  );
};

export default IconTimesSquare;
