function downloadFileBlobFormat(data, nameWithExtension) {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', nameWithExtension);

  document.body.appendChild(link);

  link.click();
  if (link.parentNode) {
    link.parentNode.removeChild(link);
  }
}

export default downloadFileBlobFormat;
