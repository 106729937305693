import React from "react";
import { Form, Input } from "antd";
import { IconEye, IconEyeSlash } from "../../../utils/Icons";

const InputUi = ({
  name,
  id,
  type,
  label,
  errorMess,
  moreRules,
  required = true,
  ...props
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[{ required: required, message: errorMess }, { ...moreRules }]}
    >
      {type === "password" ? (
        <Input.Password
          id={id}
          {...props}
          name={name}
          iconRender={(visible) =>
            visible ? (
              <IconEyeSlash width="20" height="20" fill="#fff" />
            ) : (
              <IconEye width="20" height="20" fill="#fff" />
            )
          }
        />
      ) : (
        <Input id={id} name={name} {...props} />
      )}
    </Form.Item>
  );
};

export default InputUi;
