import React from "react";
import { Button, Form, Modal } from "antd";
import { IconClose } from "../../utils/Icons";
import { InputUi } from "../ui";

const AddOfficeModal = ({
  isModalOpen,
  handleAddData,
  handleCancel,
  setFormAddOffice,
}) => {
  const [form] = Form.useForm();

  const successForm = () => {
    console.log("Success");
    handleAddData();
    form.resetFields();
  };

  const errorForm = () => {
    console.error("Error");
  };

  const handleInputChange = (field, value) => {
    setFormAddOffice((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <Modal
      title="Добавить офис"
      open={isModalOpen}
      onCancel={handleCancel}
      className="modal-closed"
      closeIcon={<IconClose width="21px" height="21px" fill="#d94c48" />}
      footer={null}
    >
      <Form
        form={form}
        name="addOffice"
        layout="vertical"
        onFinish={successForm}
        onFinishFailed={errorForm}
        autoComplete="off"
      >
        <InputUi
          name="name"
          label="Введите Имя офиса"
          placeholder={`Офис`}
          onChange={(e) => handleInputChange("name", e.target.value)}
          errorMess={`Пожалуйста, введите имя офиса`}
        />

        <div className="flex justify-center gap-10 mt-30">
          <Form.Item shouldUpdate>
            {() => (
              <Button
                htmlType="submit"
                disabled={
                  !form.isFieldsTouched(true) ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length)
                    .length
                }
                className="xs"
              >
                Добавить
              </Button>
            )}
          </Form.Item>
          <Button onClick={handleCancel} className="xs">
            Закрыть
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddOfficeModal;
