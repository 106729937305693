import React from 'react';
import { DateTime } from 'luxon';
import ReactApexChart from 'react-apexcharts';

const groupAndSumByDate = (data) => {
  const result = data.reduce((acc, item) => {
    const existing = acc.find((entry) => entry.date === item.date);

    if (existing) {
      existing.resultUSDT += item.resultUSDT;
      existing.resultRUB += item.resultRUB;
    } else {
      acc.push({
        date: item.date,
        resultUSDT: item.resultUSDT,
        resultRUB: item.resultRUB,
      });
    }

    return acc;
  }, []);

  return result;
};

const ResultChart = ({ officesWeekData }) => {
  if (!officesWeekData) return;
  officesWeekData.sort((a, b) => {
    return (
      DateTime.fromISO(a.date).toMillis() - DateTime.fromISO(b.date).toMillis()
    );
  });

  const formatedOfficesWeekData = groupAndSumByDate(officesWeekData);

  const resultChartOptions = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: formatedOfficesWeekData.map((data) =>
        DateTime.fromISO(data.date).toFormat('yyyy-MM-dd'),
      ),
    },
    yaxis: {
      title: {
        text: 'Результаты',
      },
      labels: {
        show: false,
      },
    },
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    markers: {
      size: 4,
    },
    colors: ['#00c39a', '#008FFB'],
    legend: {
      position: 'top',
    },
  };

  const resultChartSeries = [
    {
      name: 'Результат в USDT',
      data: formatedOfficesWeekData.map((data) => data.resultUSDT),
    },
    {
      name: 'Результат в RUB',
      data: formatedOfficesWeekData.map((data) => data.resultRUB),
    },
  ];

  return (
    <ReactApexChart
      options={resultChartOptions}
      series={resultChartSeries}
      type="line"
      height={350}
    />
  );
};

export default ResultChart;
