import React from "react";

const IconSearch = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  rotate = 0,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      style={{ transform: `rotate(${rotate}deg)` }}
      {...props}
    >
      <g>
        <path
          fill={fill}
          d="M21.53,21.53a.748.748,0,0,1-1.06,0L16.1,17.16a7.585,7.585,0,0,0,1.06-1.06l4.37,4.37A.754.754,0,0,1,21.53,21.53Z"
        />
        <circle fill={fill} opacity={0.3} cx="11" cy="11" r="8" />
      </g>
    </svg>
  );
};

export default IconSearch;
