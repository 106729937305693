import { Table } from "antd";
import React from "react";

const ClosedClients = ({ data }) => {
  const columns = [
    {
      title: `Имя клиента`,
      dataIndex: "name",
      key: "name",
    },
    {
      title: `Номер`,
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: `Город`,
      dataIndex: "city",
      key: "city",
    },
  ];

  return (
    <>
      <Table
        tableLayout="auto"
        dataSource={data}
        columns={columns}
        scroll={{ x: 1000 }}
      />
    </>
  );
};

export default ClosedClients;
