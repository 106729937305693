import React, { useState } from 'react';
import dayjs from 'dayjs';
import { DateTime } from 'luxon';
import { DatePicker, Form } from 'antd';
import {
  IconAngleLeft,
  IconAngleRight,
  IconAnglesLeft,
  IconAnglesRight,
  IconCalendar,
  IconTimesSquare,
} from '../../../utils/Icons';

const { RangePicker } = DatePicker;

const DatePickerUi = ({
  name,
  id,
  label,
  defaultDate,
  format,
  errorMess,
  rangePicker = false,
  containerClassName,
  ...props
}) => {
  const dd = defaultDate
    ? DateTime.fromISO(defaultDate).toISODate()
    : undefined;

  const [selectedDates, setSelectedDates] = useState(null);

  // const disabledDate = (current) => {
  //   if (!selectedDates) {
  //     return false; // нет выбранной даты, ничего не блокируем
  //   }

  //   const [start] = selectedDates;
  //   if (!start) {
  //     return false; // первая дата не выбрана, ничего не блокируем
  //   }

  //   // Ограничение выбора в пределах 7 дней
  //   const tooLate = current.diff(start, 'day') > 6;
  //   const tooEarly = start.diff(current, 'day') > 6;

  //   return tooEarly || tooLate;
  // };
  // const handleDisabledDate = (current) => {
  //   // Если первая дата выбрана, ограничиваем вторую в пределах той же недели
  //   if (selectedDates && selectedDates[0]) {
  //     const startOfWeek = selectedDates[0].startOf('week'); // Начало недели для первой даты
  //     const endOfWeek = selectedDates[0].endOf('week'); // Конец недели для первой даты

  //     return (
  //       current.isBefore(startOfWeek, 'day') ||
  //       current.isAfter(endOfWeek, 'day')
  //     );
  //   }

  //   // Если конечная дата выбрана, ограничиваем первую в пределах той же недели
  //   if (selectedDates && selectedDates[1]) {
  //     const startOfWeek = selectedDates[1].startOf('week'); // Начало недели для конечной даты
  //     const endOfWeek = selectedDates[1].endOf('week'); // Конец недели для конечной даты

  //     return (
  //       current.isBefore(startOfWeek, 'day') ||
  //       current.isAfter(endOfWeek, 'day')
  //     );
  //   }

  //   // Если нет выбранных дат, возвращаем дефолтную логику для disabledDate
  //   return disabledDate ? disabledDate(current) : false;
  // };

  const onCalendarChange = (dates) => {
    setSelectedDates(dates);
  };

  return (
    <Form.Item
      name={name}
      label={label}
      rules={[{ required: true, message: errorMess }]}
      className={containerClassName}
    >
      {rangePicker ? (
        <RangePicker
          value={selectedDates}
          onCalendarChange={onCalendarChange}
          // disabledDate={handleDisabledDate}
          allowClear
          {...props}
        />
      ) : (
        <DatePicker
          id={id}
          defaultValue={dd ? dayjs(dd) : undefined}
          format={format}
          suffixIcon={<IconCalendar width="20" height="20" fill="#00c39a" />}
          clearIcon={<IconTimesSquare width="20" height="20" fill="#d94c48" />}
          nextIcon={<IconAngleRight width="17" height="17" fill="#fff" />}
          prevIcon={<IconAngleLeft width="17" height="17" fill="#fff" />}
          superNextIcon={<IconAnglesRight width="17" height="17" fill="#fff" />}
          superPrevIcon={<IconAnglesLeft width="17" height="17" fill="#fff" />}
          {...props}
        />
      )}
    </Form.Item>
  );
};

export default DatePickerUi;
