import React from "react";

const IconTrash = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          d="M20,5.25H15.786a4.529,4.529,0,0,1-.242-.618l-.2-.607A1.5,1.5,0,0,0,13.919,3H10.081A1.5,1.5,0,0,0,8.658,4.025l-.2.607a4.529,4.529,0,0,1-.242.618H4a.75.75,0,0,0,0,1.5H20a.75.75,0,0,0,0-1.5Z"
        />
        <path
          fill={fill}
          d="M14,16.75a.75.75,0,0,1-.75-.75V11a.75.75,0,0,1,1.5,0v5A.75.75,0,0,1,14,16.75Z"
        />
        <path
          fill={fill}
          d="M10,16.75A.75.75,0,0,1,9.25,16V11a.75.75,0,0,1,1.5,0v5A.75.75,0,0,1,10,16.75Z"
        />
        <path
          fill={fill}
          opacity={0.3}
          d="M18.95,6.75,18.19,18.2a2.718,2.718,0,0,1-3,2.8H8.81a2.718,2.718,0,0,1-3-2.8L5.05,6.75Z"
        />
      </g>
    </svg>
  );
};

export default IconTrash;
