import React from "react";
import { Button, Modal } from "antd";
import { IconClose } from "../../utils/Icons";

const DeleteOfficeModal = ({ isModalOpen, officesList, choosedOfficesForDelete, handleSubmit, handleCancel }) => {
  return (
    <Modal
      title="Удалить офис"
      open={isModalOpen}
      onCancel={handleCancel}
      className="modal-closed"
      closeIcon={<IconClose width="21px" height="21px" fill="#d94c48" />}
      footer={null}
    >
      <div className="text-modal">
        Вы подтверждаете удаление офиса <b>{choosedOfficesForDelete && officesList && officesList.find(office => office.key === choosedOfficesForDelete[0] )?.name}</b> ?
      </div>
      <div className="flex justify-center gap-10 mt-30">
        <Button onClick={handleSubmit} className="xs">
          Удалить
        </Button>
        <Button onClick={handleCancel} className="xs">
          Закрыть
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteOfficeModal;
