import React from "react";

const IconClose = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          d="M6,19a1,1,0,0,1-.707-1.707l12-12a1,1,0,1,1,1.414,1.414l-12,12A1,1,0,0,1,6,19Z"
        />
        <path
          fill={fill}
          opacity={0.3}
          d="M18,19a1,1,0,0,1-.707-.293l-12-12A1,1,0,0,1,6.707,5.293l12,12A1,1,0,0,1,18,19Z"
        />
      </g>
    </svg>
  );
};

export default IconClose;
