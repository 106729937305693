import { Button, Divider, Table } from 'antd';
import { DateTime } from 'luxon';
import React, { useEffect, useCallback, useState } from 'react';
import { Layout } from '../../components/Layout';
import { IconClipboardList, IconEdit } from '../../utils/Icons';

import EditPlanModal from '../../components/Modal/EditPlanModal';
import { AlertUi } from '../../components/ui';

import {
  getAllOfficesDailyDataRequest,
  getOfficeRequest,
  // setOfficePlanRequest,
} from '../../models/office/office';

const Plan = () => {
  // const currentDate = DateTime.now().toFormat('yyyy-MM-dd');

  // Get the start of the current week (Monday as the start of the week)
  const startOfCurrentWeek = DateTime.now()
    .startOf('week') // Start of the week (Sunday by default in ISO)
    // .plus({ days: 1 }) // Shift to Monday
    .toFormat('yyyy-MM-dd');

  // Get the start of the previous week (Monday of the last week)
  // const startOfPreviousWeek = DateTime.now()
  //   .startOf('week') // Start of the current week (Sunday)
  //   // .plus({ days: 0 }) // Shift to Monday
  //   .minus({ weeks: 1 }) // Go back one week
  //   .toFormat('yyyy-MM-dd');

  const [isModalEditPlan, setIsModalEditPlan] = useState(false);
  const [selectedEditOffice, setSelectedEditOffice] = useState(null);
  const [mappedDataOffice, setMappedDataOffice] = useState([]);
  const [officesList, setOfficesList] = useState(null);
  const [paginationData, setPaginationData] = useState({
    length: null,
    pageLimit: 10,
    currentPage: 1,
  });

  const showModalEditPlan = (officeId) => {
    if (officeId) setSelectedEditOffice(officeId);
    setIsModalEditPlan(true);
  };

  const handleCancelEditPlan = () => {
    setSelectedEditOffice(null);
    setIsModalEditPlan(false);
  };

  const columns = [
    {
      title: `Название офиса`,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: `План`,
      dataIndex: 'plan',
      key: 'plan',
      render: (prise, record) => (
        <Button type="text" onClick={() => showModalEditPlan(record.key)}>
          {prise || '0'}
          <IconEdit width="20" height="20" fill="#00c39a" />
        </Button>
      ),
    },
  ];

  // fetch plan for each office but if office haven't plan at current week - get from previous
  const fetchOfficesDailyData = useCallback(
    async (data) => {
      // const startPrevWeekFormated = DateTime.fromISO(
      //   startOfPreviousWeek,
      // ).toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
      const startCurrentWeekFormated = DateTime.fromISO(
        startOfCurrentWeek,
      ).toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");

      if (!data || data.length === 0) return;

      const updatedDailyDataOffices = await Promise.all(
        data.map(async (office) => {
          try {
            const { data } = await getAllOfficesDailyDataRequest(office.ID);

            const startCurrentWeekPlan = data.DailyData.find(
              (plan) => plan.Day === startCurrentWeekFormated,
            );

            // const startPrevWeekPlan = data.DailyData.find(
            //   (plan) => plan.Day === startPrevWeekFormated,
            // );

            if (startCurrentWeekPlan) {
              return startCurrentWeekPlan;
            }
            //  else if (startPrevWeekPlan?.PlannedUsdt) {
            //   setOfficePlanRequest(
            //     office.ID,
            //     startCurrentWeekFormated,
            //     startPrevWeekPlan?.PlannedUsdt,
            //   )
            //     .then(() => {
            //       console.log(
            //         `Office (${office.Name}) plan (${startPrevWeekPlan?.PlannedUsdt} USDT) is successfully copied from previous week (${startPrevWeekFormated}).`,
            //       );
            //     })
            //     .catch((err) => {
            //       console.error(
            //         `copy previous week plan for ${office.Name} is failed, setOfficePlanRequest error:`,
            //         err,
            //       );
            //     });
            //   return startPrevWeekPlan;
            // }
          } catch (err) {}
        }),
      );

      setMappedDataOffice(updatedDailyDataOffices);
    },
    // [startOfCurrentWeek, startOfPreviousWeek],
    [startOfCurrentWeek],
  );

  useEffect(() => {
    // get all offices
    getOfficeRequest().then(({ data }) => {
      setOfficesList(data);
      setPaginationData((rest) => ({
        ...rest,
        length: data.length,
      }));
      fetchOfficesDailyData(data);
    });
  }, [fetchOfficesDailyData]);

  const officesListFormated = officesList
    ?.slice(0, paginationData.pageLimit * paginationData.currentPage)
    .map((el) => ({
      key: el.ID,
      name: el.Name,
      plan:
        mappedDataOffice?.find((data) => el.ID === data?.OfficeId)
          ?.PlannedUsdt || 0,
    }));

  return (
    <Layout section={'admin'}>
      <div className="box">
        <div className={`office`}>
          <h4 className="title">
            <span>
              <IconClipboardList width="25" height="25" fill="#00c39a" />
              План
            </span>
          </h4>

          <Divider />

          <Table
            tableLayout="auto"
            dataSource={officesListFormated}
            columns={columns}
            scroll={{ x: 768 }}
            pagination={false}
          />
          <AlertUi type="info" text={`План устанавливается на неделю.`} />

          {paginationData.length > paginationData.pageLimit && (
            <div className="actionBar">
              <Button
                onClick={() =>
                  setPaginationData((rest) => ({
                    ...rest,
                    currentPage: paginationData.currentPage + 1,
                  }))
                }
                disabled={
                  paginationData.currentPage * paginationData.pageLimit >=
                  paginationData.length
                }
              >
                Загрузить еще
              </Button>
            </div>
          )}
        </div>

        {/* Modal Edit Office */}
        <EditPlanModal
          isModalOpen={isModalEditPlan}
          selectedEditOffice={selectedEditOffice}
          fetchPlan={fetchOfficesDailyData}
          handleCancel={handleCancelEditPlan}
          officesList={officesList}
        />
      </div>
    </Layout>
  );
};

export default Plan;
