import React from 'react';
import { Toaster } from 'react-hot-toast';
import { ConfigProvider } from 'antd';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ruRu from 'antd/es/locale/ru_RU';
import Routes from './router/Routes';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

const queryClient = new QueryClient();

dayjs.locale('ru');

let updateLocale = require('dayjs/plugin/updateLocale');
let relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(updateLocale);
dayjs.extend(relativeTime);

dayjs.updateLocale('ru', {
  months:
    'Январь_Февраль_Март_Апрель_Май_Июнь_Июль_Август_Сентябрь_Октябрь_Ноябрь_Декабрь'.split(
      '_',
    ),
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider locale={ruRu}>
        <Toaster />
        <Routes />
      </ConfigProvider>
    </QueryClientProvider>
  );
}

export default App;
