import axios from "axios";
import { apiDomain } from "../../config/consts";

export const loginRequest = async (Login, Password) => {
  if (Login && Password) {
    return axios.post(`${apiDomain}/login`, {
      Login,
      Password
    })
  } else {
    console.error('Login form: some of reuired data is missed.')
  }
};

export const validateJWTRequest = async (token) => {
  return axios.post(`${apiDomain}/validate-jwt`, null,
    { headers: { 
      'Authorization': `Bearer ${token}` 
    }
  })
};