import {
  ConfigProvider,
  Button,
  Col,
  Divider,
  Input,
  Row,
  Space,
  Table,
  Checkbox,
} from 'antd';

import dayjs from 'dayjs';
import React, { useEffect, useCallback, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import PeopleChart from '../../components/Admin/PeopleChart';
import ResultChart from '../../components/Admin/ResultChart';
import { Layout } from '../../components/Layout';
import { AlertUi, DatePickerUi } from '../../components/ui';
import toast from 'react-hot-toast';
import downloadFileBlobFormat from '../../utils/downloadFileBlobFormat';
import { removeDuplicates } from '../../utils/arrays';
import { getRangeBetweenDates } from '../../utils/dates';
import {
  getOfficeRequest,
  getOfficesDailyDataRequest,
  exportOfficesDataRequest,
} from '../../models/office/office';
import {
  IconPresentationChart,
  IconSearch,
  IconExport,
} from '../../utils/Icons';

const Results = () => {
  // const [selectedDay, setSelectedDay] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [selectedOffices, setSelectedOffices] = useState([]);
  const rangeBetweenChoosenDate = selectedWeek
    ? getRangeBetweenDates(
        selectedWeek.start.format('YYYY-MM-DD'),
        selectedWeek.end.format('YYYY-MM-DD'),
      )
    : null;
  // const [isDayPickerActive, setIsDayPickerActive] = useState(false);

  const [officesList, setOfficesList] = useState(null);
  const [officesWeekData, setOfficesWeekData] = useState([]);
  const [commonStatistic, setCommonStatistic] = useState(null);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [paginationData, setPaginationData] = useState({
    length: null,
    pageLimit: 10,
    currentPage: 1,
  });

  // const [chartData, setChartData] = useState({
  //   categories: [],
  //   // peopleCount: [],
  //   // resultUSDT: [],
  //   // resultRUB: [],
  // });

  const searchInput = useRef(null);

  const handleWeekChange = (date) => {
    if (date) {
      // const startOfWeek = dayjs(date).startOf('week');
      // const endOfWeek = dayjs(date).endOf('week');
      const startOfWeek = date[0];
      const endOfWeek = date[1];

      const daysOfWeek = [];
      for (let i = 0; i < 7; i++) {
        daysOfWeek.push(startOfWeek.add(i, 'day').format('YYYY-MM-DD'));
      }

      setSelectedWeek({ start: startOfWeek, end: endOfWeek });
      // setChartData({
      //   categories: daysOfWeek,
      //   // peopleCount: [[15, 20, 25, 30, 35, 40, 45], [5, 10, 15, 20, 25, 30, 35]],
      //   // resultUSDT: [100, 150, 120, 200, 180, 220, 300],
      //   // resultRUB: [7000, 7500, 8000, 8500, 9000, 9500, 10000],
      // });
      // setIsDayPickerActive(true);
    } else {
      setSelectedWeek(null);
      // setIsDayPickerActive(false);
    }
  };

  // const disableDay = (current) => {
  //   if (!selectedWeek || !current) {
  //     return true;
  //   }
  //   return (
  //     current.isBefore(selectedWeek.start, 'day') ||
  //     current.isAfter(selectedWeek.end, 'day')
  //   );
  // };

  useEffect(() => {
    getOfficeRequest().then(({ data }) => setOfficesList(data));
  }, []);

  const setOfficesDailyData = useCallback(async (officesList, dates) => {
    if (officesList && dates && officesList.length > 0 && dates.length > 0) {
      try {
        // Array of promises for receive data of an each office and date
        const dailyDataPromises = officesList.map((office) => {
          return removeDuplicates(dates).map(async (date) => {
            try {
              const { data } = await getOfficesDailyDataRequest(
                office.ID,
                date,
              );

              if (!data) {
                console.warn(
                  `getOfficesDailyDataRequest can't find daily data for office id: ${office.ID} and date: ${date}`,
                );
                return null; // Return null, if data doesn't found
              }

              // Find name of the office
              const officeName = officesList.find(
                (item) => item.ID === data.DailyData.OfficeId,
              )?.Name;

              // Make formated object with data for offices
              return {
                key: data.DailyData.ID, // "1"
                officeId: office.ID,
                date: data.DailyData.Day,
                name: officeName || data.DailyData.OfficeId,
                plan: data.DailyData.PlannedUsdt || 0,
                peopleCount: data.DailyData.OfficePeopleCount || 0,
                resultUSDT: data.DailyData.EarnedUsdt || 0,
                resultRUB: data.DailyData.EarnedRubles || 0,
                withdrawal: data.DailyData.WithdrawnUsdt || 0,
                peopleClosed: data.ClosedClients?.length || 0,
              };
            } catch (err) {
              console.error(
                'Error fetching daily data for office:',
                office.ID,
                'and date:',
                date,
                err,
              );
              return null; // Return null in error case
            }
          });
        });

        // Wait ending of all requests with Promise.all
        const allResults = await Promise.all(
          dailyDataPromises.flat(), // Convert multilevel array into one
        );

        // Filter null values (if some of requests doesn't received data)
        const filteredResults = allResults.filter((result) => result !== null);

        // Update state with collected data
        setOfficesWeekData(filteredResults);
      } catch (err) {
        console.error('Error in setOfficesDailyData', err);
      }
    } else {
      console.warn('Error setOfficesDailyData: officeId or date is missing.');
    }
  }, []);

  useEffect(() => {
    if (!selectedWeek?.start) return;
    const startOfWeek = dayjs(selectedWeek.start).startOf('week');
    const daysOfWeek = [];
    for (let i = 0; i < 7; i++) {
      daysOfWeek.push(startOfWeek.add(i, 'day').format('YYYY-MM-DD'));
    }
    if (!daysOfWeek.length) return;
    setOfficesWeekData([]); // reset officesWeekData

    // if (officesList && selectedWeek && !selectedDay) {
    //   setOfficesDailyData(officesList, daysOfWeek);
    // } else if (officesList && selectedWeek && selectedDay) {
    //   setOfficesDailyData(officesList, [selectedDay.format('YYYY-MM-DD')]);
    // }
    setOfficesDailyData(officesList, rangeBetweenChoosenDate);
    // }, [officesList, selectedWeek, selectedDay]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [officesList, selectedWeek]);

  useEffect(() => {
    if (!officesWeekData) return;
    const summedOfficesWeekData = officesWeekData
      .sort((a, b) => a.key - b.key)
      .reduce(
        (acc, obj) => {
          acc.plan += obj.plan || 0;
          acc.peopleCount += obj.peopleCount || 0;
          acc.resultUSDT += obj.resultUSDT || 0;
          acc.resultRUB += obj.resultRUB || 0;
          acc.withdrawal += obj.withdrawal || 0;
          acc.peopleClosed += obj.peopleClosed || 0;
          return acc;
        },
        {
          plan: 0,
          peopleCount: 0,
          resultUSDT: 0,
          resultRUB: 0,
          withdrawal: 0,
          peopleClosed: 0,
        },
      );

    setCommonStatistic(summedOfficesWeekData);
  }, [officesWeekData]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Введите название офиса`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<IconSearch width="18" height="18" fill="#fff" />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: () => <IconSearch width="18" height="18" fill="#00c39a" />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    filterDropdownProps: {
      onOpenChange(open) {
        if (open) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: ' ',
      dataIndex: 'check',
      key: 'check',
      width: 50,
      render: (_, record) => {
        return (
          <Checkbox
            className="ant-checkbox"
            onChange={(e) => handlerSelectedOffices(record, e)}
          />
        );
      },
    },
    {
      title: `Название офиса`,
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: `План`,
      dataIndex: 'plan',
      key: 'plan',
    },
    {
      title: `Кол-во людей`,
      dataIndex: 'peopleCount',
      key: 'peopleCount',
      render: (_, record) => record.peopleCount.toFixed(0),
    },
    {
      title: `Результат (USDT)`,
      dataIndex: 'resultUSDT',
      key: 'resultUSDT',
    },
    {
      title: `Результат (RUB)`,
      dataIndex: 'resultRUB',
      key: 'resultRUB',
    },
    {
      title: `Вывод (USDT)`,
      dataIndex: 'withdrawal',
      key: 'withdrawal',
    },
    {
      title: `Кол-во закрытых клиентов`,
      dataIndex: 'peopleClosed',
      key: 'peopleClosed',
    },
    {
      title: `Штраф`,
      dataIndex: 'fine',
      key: 'fine',
      render: (fine) => (fine !== undefined ? fine.toFixed(2) : '0.00'),
    },
  ];

  const mergedOfficesWeekData = Object.values(
    officesWeekData
      .sort((a, b) => a.key - b.key)
      .reduce((acc, item) => {
        if (!acc[item.officeId]) {
          acc[item.officeId] = {
            officeId: item.officeId,
            name: item.name,
            plan: 0,
            peopleCount: 0,
            resultUSDT: 0,
            resultRUB: 0,
            withdrawal: 0,
            peopleClosed: 0,
            fine: 0,
          };
        }

        // const dynamics = item.plan
        //   ? ((item.plan - item.resultUSDT) / item.plan) * 100
        //   : 0;
        // const calculate = dynamics ? dynamics / 2 : 0;
        // const paying = item.resultUSDT - item.resultUSDT * 0.9;
        // const calculatePercent = calculate / 100;
        // const fine = paying ? paying - paying * calculatePercent : 0;
        const shortageOrExcess = item.plan
          ? (item.plan - item.resultUSDT) / item.plan
          : 0;
        const calculatePercent = shortageOrExcess / 2;
        const paying = item.resultUSDT - item.resultUSDT * 0.9;
        const totalAfterFine = paying - paying * calculatePercent;
        const fine = paying - totalAfterFine;

        // const stakes = fine ? paying - fine : 0;

        acc[item.officeId].plan += item.plan;
        acc[item.officeId].peopleCount += rangeBetweenChoosenDate
          ? item.peopleCount / rangeBetweenChoosenDate.length
          : item.peopleCount;
        acc[item.officeId].resultUSDT += item.resultUSDT;
        acc[item.officeId].resultRUB += item.resultRUB;
        acc[item.officeId].withdrawal += item.withdrawal;
        acc[item.officeId].peopleClosed += item.peopleClosed;
        acc[item.officeId].fine += fine;

        return acc;
      }, {}),
  );

  const handlerSelectedOffices = (record, e) => {
    if (e.target.checked) {
      setSelectedOffices((rest) => [...rest, record.officeId]);
    } else {
      setSelectedOffices((rest) =>
        rest.filter((officeId) => officeId !== record.officeId),
      );
    }
  };

  const exportOfficesDataHandler = () => {
    if (!selectedOffices.length) {
      console.error(
        'Необходимо выбрать 1 или более офисов в таблице для экспорта данных.',
      );
      toast.error(
        'Необходимо выбрать 1 или более офисов в таблице для экспорта данных.',
      );
      return;
    }
    exportOfficesDataRequest(selectedOffices)
      .then((r) => {
        console.log('Экспорт данных успешно сформирован!');
        toast.success('Экспорт данных успешно сформирован!');
        console.log(r);
        downloadFileBlobFormat(
          r.data,
          `offices-data-${selectedWeek.start.format(
            'YYYY-MM-DD',
          )}-${selectedWeek.end.format('YYYY-MM-DD')}.csv`,
        );
      })
      .catch((error) => {
        console.error(
          'Что-то пошло не так... Пожалуйста, свяжитесь с программистом.',
          error,
        );
        toast.error(
          `Что-то пошло не так... Пожалуйста, свяжитесь с программистом. Детали ошибки: ${error}`,
        );
      });
  };

  return (
    <Layout section={'admin'}>
      <div className="box">
        <div className={`result`}>
          <div className="titleWrapper">
            <h4 className="title">
              <span className="total">
                <IconPresentationChart width="25" height="25" fill="#00c39a" />
                Результаты
              </span>
            </h4>
            <ConfigProvider wave={{ disabled: true }}>
              <Button
                className="exportButton"
                onClick={exportOfficesDataHandler}
              >
                <IconExport width="25" height="25" fill="#00c39a" />
              </Button>
            </ConfigProvider>
          </div>
          <Divider />

          <div className="filter">
            <DatePickerUi
              rangePicker={true}
              name="week"
              label="Выберите дату"
              onChange={handleWeekChange}
              errorMess={`Пожалуйста, выберите диапазон дат`}
              className="rangePicker"
              containerClassName="rangePickerContainer"
              popupClassName="datarangePopup"
            />
            {/* <DatePickerUi
              name="day"
              label="Выберите дату"
              disabled={!isDayPickerActive}
              disabledDate={disableDay}
              onChange={(date) => setSelectedDay(date)}
              errorMess={`Пожалуйста, выберите неделю`}
            /> */}
          </div>

          <h5>Общая статистика:</h5>
          <div className="stat">
            <div className="item">
              <span>Среднее кол-во людей</span>
              <b>
                {commonStatistic?.peopleCount
                  ? (
                      commonStatistic.peopleCount /
                      rangeBetweenChoosenDate.length
                    ).toFixed(0)
                  : '-'}
              </b>
            </div>
            <div className="item">
              <span>Общее кол-во закрытых клиентов</span>
              <b>{commonStatistic ? commonStatistic.peopleClosed || 0 : '-'}</b>
            </div>
            <div className="item">
              <span>Результат в USDT</span>
              <b>
                {commonStatistic ? commonStatistic.resultUSDT || `0$` : '-'}
              </b>
            </div>
            <div className="item">
              <span>Результат в RUB</span>
              <b>{commonStatistic ? commonStatistic.resultRUB || `0₽` : '-'}</b>
            </div>
            <div className="item">
              <span>Вывод в USDT</span>
              <b>
                {commonStatistic ? commonStatistic.withdrawal || `0$` : '-'}
              </b>
            </div>
          </div>

          <Divider />

          <Table
            tableLayout="auto"
            dataSource={
              mergedOfficesWeekData.length
                ? mergedOfficesWeekData.slice(
                    0,
                    paginationData.currentPage * paginationData.pageLimit,
                  )
                : []
            }
            columns={columns}
            scroll={{ x: 768, y: 500 }}
            pagination={false}
            rowKey={(record, i) => `${record.key}-${i}`}
          />
          {mergedOfficesWeekData.length > paginationData.pageLimit && (
            <div className="actionBar">
              <Button
                onClick={() =>
                  setPaginationData((rest) => ({
                    ...rest,
                    currentPage: paginationData.currentPage + 1,
                  }))
                }
                disabled={
                  paginationData.currentPage * paginationData.pageLimit >=
                  mergedOfficesWeekData?.length
                }
              >
                Загрузить еще
              </Button>
            </div>
          )}

          <Row className="chartsRow">
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <div className={`result-box ${!selectedWeek ? 'inactive' : ''}`}>
                <h4>График кол-ва людей за неделю</h4>

                {!selectedWeek ? (
                  <div className="no-week-message">
                    <IconPresentationChart
                      width="55"
                      height="55"
                      fill="#666768"
                    />
                    Выберите неделю, чтобы увидеть графики.
                  </div>
                ) : (
                  <div className="chart">
                    <PeopleChart
                      officesWeekData={officesWeekData}
                      rangeBetweenChoosenDate={rangeBetweenChoosenDate}
                    />
                  </div>
                )}
              </div>
            </Col>

            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <div className={`result-box ${!selectedWeek ? 'inactive' : ''}`}>
                <h4>График результата за неделю</h4>

                {!selectedWeek ? (
                  <div className="no-week-message">
                    <IconPresentationChart
                      width="55"
                      height="55"
                      fill="#666768"
                    />
                    Выберите неделю, чтобы увидеть графики.
                  </div>
                ) : (
                  <div className="chart">
                    <ResultChart officesWeekData={officesWeekData} />
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <AlertUi
            type="info"
            text={`Чтобы увидеть результаты на графике, выберите неделю и офис, для которого хотите просмотреть данные.`}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Results;
