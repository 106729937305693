export const getRangeBetweenDates = (dateFrom, dateTo) => {
  const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateFormatRegex.test(dateFrom) || !dateFormatRegex.test(dateTo)) {
    console.error(
      'Date format is incorrect. Please use this format: yyyy-mm-dd',
    );
    return [dateFrom, dateTo];
  }

  const result = [];
  const currentDate = new Date(dateFrom);
  const endDate = new Date(dateTo);

  while (currentDate <= endDate) {
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    result.push(`${year}-${month}-${day}`);

    currentDate.setDate(currentDate.getDate() + 1);
  }

  return result;
};
