import React, { useEffect, useState } from "react";
import { Col, Divider, Row, Table } from "antd";
import { Layout } from "../../components/Layout";
import { IconDollar } from "../../utils/Icons";
import { DatePickerUi } from "../../components/ui";
import dayjs from "dayjs";
import { getOfficeRequest, getOfficesDailyDataRequest } from "../../models/office/office";

const EstimatedData = () => {
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [officesList, setOfficesList] = useState([]);
  const [mappedDataEstimated, setMappedDataEstimated] = useState([]);

  const handleWeekChange = (date) => {
    if (date) {
      const startOfWeek = dayjs(date).startOf("week");
      const endOfWeek = dayjs(date).endOf("week");

      const daysOfWeek = [];
      for (let i = 0; i < 7; i++) {
        daysOfWeek.push(startOfWeek.add(i, "day").format("YYYY-MM-DD"));
      }

      if(!officesList || !daysOfWeek.length) return
      officesList.map(office => {
        daysOfWeek.map(day => {
          getOfficesDailyDataRequest(office.ID, day).then(({data}) => {
            setMappedDataEstimated(prev => [
              ...prev,
              {
                key: data.DailyData.ID,
                name: officesList.find(office => office.ID === data.DailyData.OfficeId)?.Name,
                plan: data.DailyData.PlannedUsdt || 0,
                resultUSDT: data.DailyData.EarnedUsdt,
                dynamics: null,
                calculate: null,
                paying: null,
                fine: null,
                stakes: null,
              }
            ])
          }).catch(err => console.warn('getOfficesDailyDataRequest error:', err))
        })
      })

      setSelectedWeek({ start: startOfWeek, end: endOfWeek });
    } else {
      setSelectedWeek(null);
    }
  };

  useEffect(() => {
    getOfficeRequest().then(({data}) => setOfficesList(data))
  }, [])

  const commonResult = mappedDataEstimated.length ? mappedDataEstimated.reduce((sum, item) => sum + (item.plan || 0), 0) : 0;

  const columns = [
    {
      title: `Название офиса`,
      dataIndex: "name",
      key: "name",
    },
    {
      title: `План`,
      dataIndex: "plan",
      key: "plan",
    },
    {
      title: `Результат (USDT)`,
      dataIndex: "resultUSDT",
      key: "resultUSDT",
    },
    {
      title: `Не хватает / Перевыполнили`,
      dataIndex: "dynamics",
      key: "dynamics",
    },
    {
      title: `Забираем с КЛ`,
      dataIndex: "calculate",
      key: "calculate",
    },
    {
      title: `Зарплата КЛ`,
      dataIndex: "paying",
      key: "paying",
    },
    {
      title: `С вычетом штрафа`,
      dataIndex: "fine",
      key: "fine",
    },
    {
      title: `Сумма на ставки`,
      dataIndex: "stakes",
      key: "stakes",
    },
  ];

  // const mappedDataEstimated = [
  //   {
  //     key: "1",
  //     name: "Офис №1",
  //     plan: 100000,
  //     resultUSDT: 50000,
  //     dynamics: null,
  //     calculate: null,
  //     paying: null,
  //     fine: null,
  //     stakes: null,
  //   },
  // ];

  const processedData = mappedDataEstimated.map((item) => {
    const dynamics = item.plan
      ? ((item.plan - item.resultUSDT) / item.plan) * 100
      : 0;

    const calculate = dynamics ? dynamics / 2 : 0;

    const paying = item.resultUSDT - item.resultUSDT * 0.9;

    const calculatePercent = calculate / 100;

    const fine = paying ? paying - (paying * calculatePercent).toFixed(2) : 0;

    const stakes = fine ? (paying - fine).toFixed(2) : 0;

    console.log(calculate, calculatePercent);

    return {
      ...item,
      dynamics,
      calculate,
      paying: paying.toFixed(2),
      fine,
      stakes,
    };
  });
  
  const stakeResult = processedData.length ? processedData.reduce((sum, item) => sum + (Number(item.stakes) || 0), 0) : 0;

  return (
    <Layout section={"admin"}>
      <div className="box">
        <div className={`estimated`}>
          <h4 className="title">
            <span>
              <IconDollar width="25" height="25" fill="#00c39a" />
              Расчетные данные
            </span>
          </h4>

          <Divider />

          <Row align="middle">
            <Col xl={6} lg={6} md={24} sm={24} xs={24}>
              <DatePickerUi
                name="week"
                label="Выберите неделю"
                picker="week"
                onChange={handleWeekChange}
                errorMess={`Пожалуйста, выберите неделю`}
              />
            </Col>
            <Col xl={10} lg={10} md={24} sm={24} xs={24}>
              <h5>Общая статистика:</h5>
              <div className="stat">
                <div className="item">
                  <span>Общий результат</span>
                  <b>{commonResult}</b>
                </div>
                <div className="item">
                  <span>Сумма на ставки</span>
                  <b>{stakeResult}</b>
                </div>
              </div>
            </Col>
          </Row>

          <Table
            tableLayout="auto"
            dataSource={processedData}
            columns={columns}
            scroll={{ x: 768 }}
          />
        </div>
      </div>
    </Layout>
  );
};

export default EstimatedData;
