import React from "react";
import { Button, Form, Modal } from "antd";
import { DatePickerUi, InputUi } from "../ui";
import { IconClose } from "../../utils/Icons";

const ClosedClientsModal = ({
  isModalOpen,
  handleAddData,
  handleCancel,
  setFormClosedClients,
}) => {
  const [form] = Form.useForm();

  const successForm = () => {
    console.log("Success");
    handleAddData();
    form.resetFields();
  };

  const errorForm = () => {
    console.error("Error");
  };

  const handleInputChange = (field, value) => {
    setFormClosedClients((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <Modal
      title="Добавить клиента"
      open={isModalOpen}
      onCancel={handleCancel}
      className="modal-closed"
      closeIcon={<IconClose width="21px" height="21px" fill="#d94c48" />}
      footer={null}
    >
      <Form
        form={form}
        name="addClosedClients"
        layout="vertical"
        onFinish={successForm}
        onFinishFailed={errorForm}
        autoComplete="off"
      >
        <InputUi
          name="name"
          label="Введите Имя клиента"
          placeholder={`Имя клиента`}
          onChange={(e) => handleInputChange("name", e.target.value)}
          errorMess={`Пожалуйста, введите имя клиента`}
        />
        <InputUi
          name="phone"
          label="Введите Номер телефона"
          placeholder={`Номер телефона`}
          onChange={(e) => handleInputChange("phone", e.target.value)}
          errorMess={`Пожалуйста, введите номер телефона`}
        />
        <InputUi
          name="city"
          label="Введите Город"
          placeholder={`Город`}
          onChange={(e) => handleInputChange("city", e.target.value)}
          errorMess={`Пожалуйста, введите номер телефона`}
        />
        <DatePickerUi
          name="day"
          label="Выберите дату"
          onChange={(e) => handleInputChange("date", e)}
          errorMess={`Пожалуйста, выберите дату`}
        />

        <div className="flex justify-center gap-10 mt-30">
          <Form.Item shouldUpdate>
            {() => (
              <Button
                htmlType="submit"
                disabled={
                  !form.isFieldsTouched(true) ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length)
                    .length
                }
                className="xs"
              >
                Добавить
              </Button>
            )}
          </Form.Item>
          <Button onClick={handleCancel} className="xs">
            Закрыть
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ClosedClientsModal;
