import React, { useEffect, useCallback, useRef, useState } from 'react';
import { Button, Col, Divider, Row } from 'antd';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { DateTime } from 'luxon';
import toast from 'react-hot-toast';
import ClosedClientsModal from '../components/Modal/ClosedClientsModal';
import { AlertUi, DatePickerUi, InputNumberUi } from '../components/ui';
import { useDebounce } from '../hooks/useDebounce';
import ClosedClients from '../components/User/ClosedClients';
import PeopleChart from '../components/User/PeopleChart';
import ResultChart from '../components/User/ResultChart';
import {
  getOfficesDailyDataRequest,
  updateOfficeDataRequest,
} from '../models/office/office';
import {
  createClosedClientRequest,
  getAvailableOfficesRequest,
} from '../models/user/user';
import { IconLogIn, IconPresentationChart } from '../utils/Icons';

const User = () => {
  const history = useHistory();
  const cookies = new Cookies();

  const countOfWorkingDays = 6;
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState({
    start: dayjs().startOf('week'),
    end: dayjs().endOf('week'),
  });

  const [isDayPickerActive, setIsDayPickerActive] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [officesWeekData, setOfficesWeekData] = useState([]);
  const [availableOffices, setAvailableOffices] = useState(null);
  const [peopleCount, setPeopleCount] = useState(null);
  const [requestLoading, setRequestLoading] = useState({
    updateOfficeDataRequestLoading: false,
  });

  const debouncedPeopleCount = useDebounce(peopleCount, 500);

  const [formResultData, setFormResultData] = useState({
    peopleCount: null,
    resultUSDT: null,
    resultRUB: null,
    withdrawal: null,
  });

  const [formClosedClients, setFormClosedClients] = useState({
    name: '',
    phone: '',
    city: '',
    usdt: null,
    rub: null,
  });

  const inputUsers = useRef(null);
  const userCountInputRef = useRef();

  const handleWeekChange = (date) => {
    if (date) {
      const startOfWeek = dayjs(date).startOf('week');
      const endOfWeek = dayjs(date).endOf('week');

      setSelectedWeek({ start: startOfWeek, end: endOfWeek });
      setIsDayPickerActive(true);
    } else {
      setSelectedWeek(null);
      setIsDayPickerActive(false);
    }
  };

  const disableDay = (current) => {
    if (!selectedWeek || !current) {
      return true;
    }
    return (
      current.isBefore(selectedWeek.start, 'day') ||
      current.isAfter(selectedWeek.end, 'day')
    );
  };

  const handleInputChange = (field, value) => {
    setFormResultData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setFormClosedClients({
      name: '',
      phone: '',
      city: '',
      usdt: null,
      rub: null,
    });
  };

  const handleAddData = () => {
    const newEntry = {
      key: String(mappedDataTable.length + 1),
      ...formClosedClients,
    };

    if (!newEntry.date)
      return console.error('Add closed client error: missed date!');

    const date = newEntry.date.format('YYYY-MM-DD');

    if (!availableOffices)
      return toast.error('Вы не закреплены ни за одним офисом!');
    getOfficesDailyDataRequest(availableOffices[0]?.OfficeId, date)
      .then(({ data }) => {
        createClosedClientRequest(
          data.DailyData.ID,
          newEntry.name,
          newEntry.phone,
          newEntry.city,
        )
          .then(() => {
            // Close modal
            handleCancel();
            // show success message
            toast.success('Закрытый клиент успешно добавлен!');
            // reload daily data
            fetchDailyData();
          })
          .catch((err) => {
            console.warn('Error createClosedClientRequest', err);

            // Check if the error is an Axios error to get a more descriptive message
            const errorMessage = err.response
              ? err.response.data || 'Create closed client error!'
              : 'Network error';

            // Show error toast
            toast.error(errorMessage);
          });
      })
      .catch((err) => {
        updateOfficeDataRequest(
          availableOffices[0]?.OfficeId,
          DateTime.fromISO(date).toFormat("yyyy-MM-dd'T'HH:mm:ss'Z"),
        )
          .then(() => {
            handleAddData();
          })
          .catch((err) => {
            console.warn('Error updateOfficeDataRequest', err);

            // Check if the error is an Axios error to get a more descriptive message
            const errorMessage = err.response
              ? err.response.data || 'Update office data error'
              : 'Network error';

            // Show error toast
            toast.error(errorMessage);
          });
      });
  };

  useEffect(() => {
    console.log('availableOffices', officesWeekData);
    console.log('peopleCount?.length', peopleCount?.length);
    console.log('selectedWeek, selectedDay', selectedWeek, selectedDay);
    // console.log(
    //   'startOfCurrentWeek, endOfCurrentWeek',
    //   startOfCurrentWeek,
    //   endOfCurrentWeek,
    // );
    // console.log(
    //   'selectedWeek?.start',
    //   selectedWeek?.start?.format('YYYY-MM-DD'),
    // );
  }, [officesWeekData, peopleCount, selectedWeek, selectedDay]);

  const setOfficesDailyData = (officeId, date) => {
    if (!officeId || !date)
      return console.warn(
        'Error setOfficesDailyData: officeId or date is missing.',
      );
    getOfficesDailyDataRequest(officeId, date)
      .then(({ data }) => {
        if (!data) return;
        const dailyData = data.DailyData;
        setOfficesWeekData((prev) => {
          return [
            ...prev,
            {
              key: dailyData.ID, //"1",
              date: dailyData.Day,
              officeId: officeId,
              plan: dailyData.PlannedUsdt || 0,
              peopleCount: dailyData.OfficePeopleCount || 0,
              resultUSDT: dailyData.EarnedUsdt || 0,
              resultRUB: dailyData.EarnedRubles || 0,
              withdrawal: dailyData.WithdrawnUsdt || 0,
              peopleClosed: 0,
              closedClients: data.ClosedClients,
            },
          ];
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // get all available offices request
    getAvailableOfficesRequest()
      .then(({ data }) => {
        if (data) setAvailableOffices(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const fetchDailyData = useCallback(() => {
    if (!selectedWeek?.start || !availableOffices?.[0]?.OfficeId) return;
    const startOfWeek = dayjs(selectedWeek.start).startOf('week');
    const daysOfWeek = [];
    for (let i = 0; i < 7; i++) {
      daysOfWeek.push(startOfWeek.add(i, 'day').format('YYYY-MM-DD'));
    }
    if (!daysOfWeek.length) return;
    setOfficesWeekData([]); // reset officesWeekData
    console.log('daysOfWeek', daysOfWeek);
    daysOfWeek.forEach((date) => {
      setOfficesDailyData(availableOffices[0]?.OfficeId, date);
    });
  }, [availableOffices, selectedWeek?.start]);

  useEffect(
    () => fetchDailyData(),
    [availableOffices, fetchDailyData, selectedWeek],
  );

  const savePeopleHandler = () => {
    const day = DateTime.fromISO(
      selectedDay
        ? selectedDay.format('YYYY-MM-DD')
        : selectedWeek?.start.format('YYYY-MM-DD'),
    ).toFormat("yyyy-MM-dd'T'HH:mm:ss'Z");

    if (!availableOffices)
      return toast.error('Вы не закреплены ни за одним офисом!');

    updateOfficeDataRequest(
      availableOffices[0]?.OfficeId,
      day,
      undefined,
      formResultData.peopleCount,
      formResultData.peopleOnline,
    )
      .then(() => {
        toast.success('Успешно сохранено');
      })
      .catch((err) => {
        console.warn('Error updateOfficeDataRequest', err);

        // Check if the error is an Axios error to get a more descriptive message
        const errorMessage = err.response
          ? err.response.data || 'Update office data error'
          : 'Network error';

        // Show error toast
        toast.error(errorMessage);
      });
    setFormResultData((prev) => ({
      ...prev,
      peopleCount: null, // Reset the users InputNumberUi value
      peopleOnline: null, // Reset the users InputNumberUi value
    }));
    inputUsers.current.reset();
  };

  const saveUsdtRubHandler = () => {
    const day = DateTime.fromISO(
      selectedDay
        ? selectedDay.format('YYYY-MM-DD')
        : selectedWeek?.start.format('YYYY-MM-DD'),
    ).toFormat("yyyy-MM-dd'T'HH:mm:ss'Z");

    if (!availableOffices)
      return toast.error('Вы не закреплены ни за одним офисом!');
    updateOfficeDataRequest(
      availableOffices[0]?.OfficeId,
      day,
      undefined,
      undefined,
      undefined,
      formResultData.resultUSDT,
      formResultData.resultRUB,
    )
      .then(() => {
        toast.success('Успешно сохранено');
      })
      .catch((err) => {
        console.warn('Error updateOfficeDataRequest', err);

        // Check if the error is an Axios error to get a more descriptive message
        const errorMessage = err.response
          ? err.response.data || 'Update office data error'
          : 'Network error';

        // Show error toast
        toast.error(errorMessage);
      });
  };

  const saveWithdrawalHandler = () => {
    const day = DateTime.fromISO(
      selectedDay
        ? selectedDay.format('YYYY-MM-DD')
        : selectedWeek?.start.format('YYYY-MM-DD'),
    ).toFormat("yyyy-MM-dd'T'HH:mm:ss'Z");

    if (!availableOffices)
      return toast.error('Вы не закреплены ни за одним офисом!');
    updateOfficeDataRequest(
      availableOffices[0]?.OfficeId,
      day,
      formResultData.withdrawal,
    )
      .then(() => {
        toast.success('Успешно сохранено');
      })
      .catch((err) => {
        console.warn('Error updateOfficeDataRequest', err);

        // Check if the error is an Axios error to get a more descriptive message
        const errorMessage = err.response
          ? err.response.data || 'Update office data error'
          : 'Network error';

        // Show error toast
        toast.error(errorMessage);
      });
  };

  const mappedDataTable = officesWeekData.flatMap((dayData) => {
    if (dayData.closedClients) {
      return dayData.closedClients.map((client) => ({
        key: client.ID,
        name: client.Name,
        phone: client.Number,
        city: client.City,
      }));
    }
    // return empty array if closedClients isn't exist
    return [];
  });

  const weekPlan = officesWeekData?.reduce((sum, item) => sum + item.plan, 0);
  const weekResult = officesWeekData?.reduce(
    (sum, item) => sum + item.resultUSDT,
    0,
  );

  const sumPlanAndResultUSDT = officesWeekData?.reduce(
    (acc, item) => {
      acc.plan += item.plan;
      acc.resultUSDT += item.resultUSDT;
      return acc;
    },
    { plan: 0, resultUSDT: 0 },
  );

  const shortageOrExcess =
    (sumPlanAndResultUSDT.plan - sumPlanAndResultUSDT.resultUSDT) /
    sumPlanAndResultUSDT.plan;
  const calculatePercent = shortageOrExcess / 2;
  const paying =
    sumPlanAndResultUSDT.resultUSDT - sumPlanAndResultUSDT.resultUSDT * 0.9;
  const totalAfterFine = paying - paying * calculatePercent;
  const fine = paying - totalAfterFine;

  const logOutHandler = () => {
    console.log('window.location.host', window.location.host);
    cookies.remove('at', {
      path: '/',
      domain: window.location.host.includes('localhost')
        ? 'localhost'
        : `.${window.location.host}`,
      secure: true,
    });
    history.push('/');
  };

  const changePeopleCountHandler = (e) => {
    const inputValue = e.target.value;

    if (!/^\d+$/.test(inputValue)) {
      toast.error('Пожалуйста, убедитесь, что поле содержит только цифры.');
      return;
    }

    setPeopleCount(inputValue);
  };

  useEffect(() => {
    if (!debouncedPeopleCount) return;

    const day = DateTime.fromISO(
      selectedDay
        ? selectedDay.format('YYYY-MM-DD')
        : selectedWeek?.start.format('YYYY-MM-DD'),
    ).toFormat("yyyy-MM-dd'T'HH:mm:ss'Z");
    const peopleCountFormated = parseInt(peopleCount);

    if (!peopleCountFormated)
      toast.error(
        'Количество людей не может быть пустым, минимально допустимое значение = 0.',
      );

    setRequestLoading((rest) => ({
      ...rest,
      updateOfficeDataRequestLoading: true,
    }));

    if (requestLoading.updateOfficeDataRequestLoading) return;

    updateOfficeDataRequest(
      availableOffices[0]?.OfficeId,
      day,
      undefined,
      peopleCountFormated,
    )
      .then(() => {
        setRequestLoading((rest) => ({
          ...rest,
          updateOfficeDataRequestLoading: false,
        }));
        // Add green background for 1 sec
        if (userCountInputRef.current) {
          userCountInputRef.current.classList.add('request-success');

          const timeoutId = setTimeout(() => {
            userCountInputRef.current?.classList.remove('request-success');
          }, 1000);

          // Clear timer on unmount
          return () => clearTimeout(timeoutId);
        }
      })
      .catch((err) => {
        console.warn('Error updateOfficeDataRequest', err);

        // Check if the error is an Axios error to get a more descriptive message
        const errorMessage = err.response
          ? err.response.data || 'Update office data error'
          : 'Network error';

        // Show error toast
        toast.error(errorMessage);
      });
  }, [debouncedPeopleCount]);

  return (
    <section className="user">
      <div className="header">
        <h2>Добро пожаловать</h2>
        <div className="actionPanel">
          {!!officesWeekData?.[0]?.peopleCount && (
            <div className="infoPanel">
              <div className="infoPanel__infoRow">
                <div className="infoPanel__infocol">
                  <div className="infoPanel__label">
                    Среднее количество людей:
                  </div>
                  <div className="infoPanel__value">
                    {(
                      officesWeekData?.[0]?.peopleCount / countOfWorkingDays
                    ).toFixed(0)}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="textInput">
            <span className="textInput__label">
              Количество людей по списку:
            </span>
            <div className="textInput__field">
              <input
                key={officesWeekData?.[0]?.peopleCount ?? 'default'}
                ref={userCountInputRef}
                type="number"
                className="textInput__input"
                defaultValue={officesWeekData?.[0]?.peopleCount ?? 0}
                min={0}
                max={99999}
                onChange={changePeopleCountHandler}
                style={{
                  width: `${
                    peopleCount?.length ||
                    officesWeekData?.[0]?.peopleCount?.toString().length
                  }ch`,
                }}
              />
            </div>
          </div>
          <span onClick={logOutHandler} className="button">
            Выйти
            <IconLogIn width="25" height="25" fill="#00c39a" />
          </span>
        </div>
      </div>

      <div className="stat-info">
        <span>
          Результат за неделю: <b>{weekResult}$</b>
          {fine > 0 && <s>Штраф - {fine > 0 && fine.toFixed(2)}$</s>}
        </span>
        <span>
          План за неделю: <b>{weekPlan}$</b>
        </span>
      </div>

      <div className="box">
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <DatePickerUi
              name="week"
              label="Выберите неделю"
              picker="week"
              defaultDate={selectedWeek.start.format('YYYY-MM-DD')}
              onChange={handleWeekChange}
              errorMess={`Пожалуйста, выберите неделю`}
            />
            <DatePickerUi
              name="day"
              label="Выберите дату"
              disabled={!isDayPickerActive}
              disabledDate={disableDay}
              onChange={(date) => setSelectedDay(date)}
              errorMess={`Пожалуйста, выберите неделю`}
            />
            <AlertUi
              type="info"
              text={`Выберите дату в диапазоне недели, которую вы выбрали.`}
            />

            <Row className="mt-50 boxes-row">
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <div className={`result ${!selectedWeek ? 'inactive' : ''}`}>
                  <h4>Количество людей</h4>
                  <div className="amount">
                    {/* <InputNumberUi
                      name="users"
                      label="Введите общее кол-во людей"
                      placeholder={10}
                      ref={inputUsers}
                      onChange={(value) =>
                        handleInputChange('peopleCount', value)
                      }
                      errorMess={`Error`}
                    /> */}
                    <InputNumberUi
                      name="usersOnline"
                      label="Введите кол-во на работе"
                      placeholder={10}
                      onChange={(value) =>
                        handleInputChange('peopleOnline', value)
                      }
                      errorMess={`Error`}
                    />
                  </div>
                  <div className="flex justify-center mt-20">
                    {console.log(
                      'formResultData.peopleOnline',
                      !(
                        formResultData.peopleOnline?.toString() === '0' &&
                        formResultData.peopleCount?.toString() === '0'
                      ),
                    )}
                    <Button
                      htmlType="button"
                      className="xs"
                      onClick={savePeopleHandler}
                      disabled={
                        !formResultData.peopleOnline?.toString() &&
                        !formResultData.peopleCount?.toString()
                      }
                    >
                      Сохранить
                    </Button>
                  </div>
                </div>
              </Col>

              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <div className={`result ${!selectedWeek ? 'inactive' : ''}`}>
                  <h4>Результат в USDT | RUB</h4>
                  <div className="amount">
                    <InputNumberUi
                      name="usdt"
                      label="Введите результат в USDT"
                      placeholder={`100$`}
                      onChange={(value) =>
                        handleInputChange('resultUSDT', value)
                      }
                      errorMess={`Error`}
                    />
                    <InputNumberUi
                      name="rub"
                      label="Введите результат в RUB"
                      placeholder={`10 000`}
                      onChange={(value) =>
                        handleInputChange('resultRUB', value)
                      }
                      errorMess={`Error`}
                    />
                  </div>
                  <div className="flex justify-center mt-20">
                    <Button
                      htmlType="button"
                      className="xs"
                      onClick={saveUsdtRubHandler}
                      disabled={
                        !formResultData.resultUSDT?.toString() &&
                        !formResultData.resultRUB?.toString()
                      }
                    >
                      Сохранить
                    </Button>
                  </div>
                </div>
              </Col>

              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className={`result ${!selectedWeek ? 'inactive' : ''}`}>
                  <h4>Вывод за неделю в USDT</h4>
                  <InputNumberUi
                    name="withdrawal"
                    label="Введите вывод"
                    placeholder={10}
                    onChange={(value) => handleInputChange('withdrawal', value)}
                    errorMess={`Error`}
                  />
                  <div className="flex justify-center mt-20">
                    <Button
                      htmlType="button"
                      className="xs"
                      onClick={saveWithdrawalHandler}
                    >
                      Сохранить
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <div className={`result ${!selectedWeek ? 'inactive' : ''}`}>
                  <h4>График кол-ва людей за неделю</h4>

                  {!selectedWeek ? (
                    <div className="no-week-message">
                      <IconPresentationChart
                        width="55"
                        height="55"
                        fill="#666768"
                      />
                      Выберите неделю, чтобы увидеть графики.
                    </div>
                  ) : (
                    <div className="chart">
                      <PeopleChart officesWeekData={officesWeekData} />
                    </div>
                  )}
                </div>
              </Col>

              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <div className={`result ${!selectedWeek ? 'inactive' : ''}`}>
                  <h4>График результата за неделю</h4>

                  {!selectedWeek ? (
                    <div className="no-week-message">
                      <IconPresentationChart
                        width="55"
                        height="55"
                        fill="#666768"
                      />
                      Выберите неделю, чтобы увидеть графики.
                    </div>
                  ) : (
                    <div className="chart">
                      <ResultChart officesWeekData={officesWeekData} />
                    </div>
                  )}
                </div>
              </Col>
            </Row>

            <Divider />

            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className="closed-clients">
                  <h4>
                    Закрытые клиенты
                    <Button
                      htmlType="button"
                      className="xs"
                      onClick={showModal}
                    >
                      Добавить Клиента
                    </Button>
                  </h4>
                  <ClosedClients data={mappedDataTable} />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Modal Closed Clients */}
        <ClosedClientsModal
          isModalOpen={isModalOpen}
          handleAddData={handleAddData}
          handleCancel={handleCancel}
          setFormClosedClients={setFormClosedClients}
        />
      </div>
    </section>
  );
};

export default User;
