import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import { useHistory } from 'react-router-dom';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import ChangeUserPasswordModal from './Modal/ChangeUserPasswordModal';
import { resetPasswordSuperadminRequest } from '../models/user/user';
import {
  IconClipboardList,
  IconCompiling,
  IconHome,
  IconLogIn,
  IconPresentationChart,
  IconUserCheck,
  IconUsersGroup,
  IconUserTimes,
  IconEdit,
} from '../utils/Icons';

const SideBar = ({ isActive }) => {
  const cookies = new Cookies();
  const history = useHistory();
  const location = useLocation();

  const [isChangeUserPasswordModalOpened, setIsChangeUserPasswordModalOpened] =
    useState(false);
  const [newUserData, setNewUserData] = useState({});

  const logOutHandler = () => {
    cookies.remove('at', {
      path: '/',
      domain: window.location.host.includes('localhost')
        ? 'localhost'
        : `.${window.location.host}`,
      secure: true,
    });
    history.push('/');
  };

  const items = [
    {
      label: <Link to="/admin/moderators">Модераторы</Link>,
      path: '/admin/moderators',
      key: '1',
      icon: <IconUsersGroup width="25" height="25" fill="#00c39a" />,
    },
    {
      label: <Link to="/admin/office">Офиса</Link>,
      path: '/admin/office',
      key: '2',
      icon: <IconHome width="25" height="25" fill="#00c39a" />,
    },
    {
      label: <Link to="/admin/plan">План</Link>,
      path: '/admin/plan',
      key: '3',
      icon: <IconClipboardList width="25" height="25" fill="#00c39a" />,
    },
    {
      label: <Link to="/admin/results">Результаты</Link>,
      path: '/admin/results',
      key: '4',
      icon: <IconPresentationChart width="25" height="25" fill="#00c39a" />,
    },
    {
      label: <Link to="/admin/staff">Сегодня</Link>,
      path: '/admin/staff',
      key: '5',
      icon: <IconUserCheck width="25" height="25" fill="#00c39a" />,
    },
    {
      label: <Link to="/admin/closed">Закрытые клиенты</Link>,
      path: '/admin/closed',
      key: '6',
      icon: <IconUserTimes width="25" height="25" fill="#00c39a" />,
    },
    {
      label: <span className="link">Сменить пароль</span>,
      onClick: () => setIsChangeUserPasswordModalOpened(true),
      key: '7',
      icon: <IconEdit width="25" height="25" fill="#00c39a" />,
    },
    {
      label: (
        <span className="link" onClick={logOutHandler}>
          Выйти
        </span>
      ),
      key: '8',
      icon: <IconLogIn width="25" height="25" fill="#00c39a" />,
    },
  ];

  const selectedKey = items.find((item) => item.path === location.pathname);
  const resultItems = items.reduce(
    (acc, item) => (item ? [...acc, item] : acc),
    [],
  );

  const handleCancelChangeUserPasswordModal = () => {
    setIsChangeUserPasswordModalOpened(false);
  };

  const handleResetPasswordSubmit = () => {
    if (!newUserData.password) {
      console.error('No password to reset');
      toast.error('No password to reset');
    }
    resetPasswordSuperadminRequest(newUserData.password)
      .then(() => {
        console.error('Password successfully changed!');
        toast.success('Password successfully changed!');
      })
      .catch((error) => {
        console.error(
          'Something went wrong. Please contact with programmer.',
          error,
        );
        toast.error(
          `Something went wrong. Please contact with programmer. Error datails: ${error}`,
        );
      });
  };

  return (
    <>
      <div className={`side-bar ${isActive ? 'active' : ''}`}>
        <Link to="/">
          <img
            src={require('../assets/img/logo.svg').default}
            className="logo"
            alt=""
          />
        </Link>
        <span className="subtitle">
          <IconCompiling width="20" height="20" fill="#ababab" />
          Навигация:
        </span>
        <Menu
          className="side-bar-menu"
          defaultSelectedKeys={['1']}
          selectedKeys={[selectedKey?.key]}
          mode="inline"
          theme="dark"
          inlineCollapsed={false}
          items={resultItems}
        />
        <ChangeUserPasswordModal
          isModalOpen={isChangeUserPasswordModalOpened}
          setNewUserData={setNewUserData}
          handleCancel={handleCancelChangeUserPasswordModal}
          handleSubmit={handleResetPasswordSubmit}
        />
      </div>
    </>
  );
};

export default SideBar;
