import React from 'react';
import { Button, Form, Modal } from 'antd';
import { IconClose } from '../../utils/Icons';
import { InputUi } from '../ui';

const ChangeUserPasswordModal = ({
  isModalOpen,
  handleSubmit,
  handleCancel,
  setNewUserData,
}) => {
  const [form] = Form.useForm();

  const successForm = () => {
    console.log('Success');
    handleSubmit();
    form.resetFields();
  };

  const errorForm = () => {
    console.error('Error');
  };

  const handleInputChange = (field, value) => {
    setNewUserData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <Modal
      title="Изменить пароль"
      open={isModalOpen}
      onCancel={handleCancel}
      className="modal-closed"
      closeIcon={<IconClose width="21px" height="21px" fill="#d94c48" />}
      footer={null}
    >
      <Form
        form={form}
        name="addOffice"
        layout="vertical"
        onFinish={successForm}
        onFinishFailed={errorForm}
        autoComplete="off"
      >
        <InputUi
          name="password"
          type="password"
          label={`Введите новый пароль`}
          placeholder="*******"
          onChange={(e) => handleInputChange('password', e.target.value)}
          errorMess={`Пожалуйста, введите пароль`}
        />

        <div className="flex justify-center gap-10 mt-30">
          <Form.Item shouldUpdate>
            {() => (
              <Button
                htmlType="submit"
                disabled={
                  !form.isFieldsTouched(true) ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length)
                    .length
                }
                className="xs"
              >
                Изменить
              </Button>
            )}
          </Form.Item>
          <Button onClick={handleCancel} className="xs">
            Закрыть
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ChangeUserPasswordModal;
